// Migrated
<template>
  <div
    v-if="tabs?.length"
    ref="container"
    class="w-full pretty-tabs-nav-container"
  >
    <TabsNav
      ref="tabs"
      class="mb-0 flex pretty-tabs-nav"
      v-bind="navTabsProps"
      :is-member="isMember"
      @on-tab-change="onTabChange"
    >
      <nuxt-link
        v-for="(tab, tabIndex) in tabs"
        :key="tab.text"
        class="pretty-tabs-nav-link inline-flex justify-center md:justify-start items-center h-full pr-0 md:pr-12 mb-0 text-center"
        :to="createNuxtLinkToObj(tab)"
        :class="isActiveTab(tab.localeUrlKey) ? 'active' : '!text-slate-500'"
        role="tab"
        :aria-selected="isActiveTab(tab.localeUrlKey)"
      >
        <div
          :ref="(element) => setTabRef(tabIndex, element)"
          class="tabs-text mb-0 font-normal"
          :data-i18n="tab?.translationKey || tab?.text"
        >
          {{ $t(tab.text) }}
        </div>
      </nuxt-link>
    </TabsNav>
    <div
      v-show="hydrated"
      class="w-full relative pointer-events-none"
      style="height: 0"
    >
      <svg
        ref="activeTrianglePointer"
        class="pretty-tabs-active-triangle-pointer absolute"
        viewBox="0 0 100 100"
      >
        <polygon
          points="50 65, 100 100, 0 100"
          fill="currentColor"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import TabsNav from '@layers/web/components/common/TabsNav'

export default defineNuxtComponent({
  setup () {
    const { urls: localeUrlsRaw } = useLocale()
    const route = useRoute()
    const slug = route.params.slug
    return {
      localeUrlsRaw,
      slug,

    }
  },
  props: {
    ...TabsNav.props,

    /**
     * @prop {Array<
     *   text: string
     *   to: string | object
     *   localeUrlKey: string
     * >} tabs
     */
    tabs: {
      type: Array,
      default: () => [],
    },

    /**
     * @prop {Boolean} keepParams
     * Will spread all params into nuxt-link params, including those you specify in props.tabs[].to.query (if object)
     */
    keepQueryParams: {
      type: Boolean,
      default: false,
    },

    animated: {
      type: Boolean,
      default: false,
    },

    isSol: {
      type: Boolean,
      default: false,
    },

    isMember: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['onTabChange'],

  data () {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    const { tabs, isMember, ...navTabsProps } = this.$props

    const activeTab = this.isMember ? findKeyBySlug(this.slug, this.localeUrlsRaw.authSlug) : findActiveSlugKey(this.localeUrls, this.$route.params)

    return {
      navTabsProps,
      activeTab: activeTab || this.tabs?.[0]?.localeUrlKey || '',
      tabRefs: [],
      trianglePointerAnimation: 'transform 125ms cubic-bezier(.57,.21,.69,1.25)',
      hydrated: false,
    }
  },

  mounted () {
    this.hydrated = true
    this.alignActiveTrianglePointer()
    window.addEventListener('resize', this.alignActiveTrianglePointer)
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.alignActiveTrianglePointer)
  },

  updated () {
    this.alignActiveTrianglePointer()
  },

  methods: {
    onTabChange (newActiveTab) {
      this.activeTab = newActiveTab
      this.$emit('onTabChange', newActiveTab)
    },

    isActiveTab (tab) {
      return this.activeTab === tab
    },

    createNuxtLinkToObj (tab) {
      let to = tab.to

      if (this.keepQueryParams) {
        switch (typeof tab.to) {
          case 'object':
            to.query = {
              ...this.$route.query,
              ...(to.params || {}),
            }
            break

          case 'string':
            to = {}
            to.query = this.$route.query
            to.path = tab.to
            break
        }
      }

      return to
    },

    setTabRef (tabIndex, element) {
      if (element) {
        this.tabRefs[tabIndex] = element
      }
    },

    findActiveTabElement () {
      const activeTabIndex = this.tabs.findIndex(
        tab => tab.localeUrlKey === this.activeTab
      )
      return this.tabRefs[activeTabIndex]
    },

    alignActiveTrianglePointer () {
      if (!process.browser) {
        return
      }

      const activeTabElement = this.findActiveTabElement()

      if (
        activeTabElement &&
        this.$refs.container &&
        this.$refs.activeTrianglePointer
      ) {
        if (this.animated) {
          if (this.initialized) {
            this.$refs.activeTrianglePointer.style.transition = this.trianglePointerAnimation
          } else if (!this.initialized) {
            this.initialized = true
          }
        }

        const { offsetWidth: tabWidth, offsetLeft: tabLeft } = activeTabElement
        const { offsetLeft: containerLeft } = this.$refs.container
        const { clientWidth: triangleWidth } = this.$refs.activeTrianglePointer

        this.$refs.activeTrianglePointer.style.transform = `translateX(${(tabLeft - containerLeft) + (tabWidth / 2) - (triangleWidth / 2)}px)`
      }
    },
  },
})
</script>

<style lang="scss" scoped>
$prettyNavBg: theme('colors.white-blue');

.tabs-text {
  font-size: 1rem;
}
.pretty-tabs-nav-container {
  clip-path: inset(0 -50vw 0 -50vw);
  letter-spacing: 0.5px;
  max-width: 100%;

  &:before {
    content: '';
    height: 100vh;
    width: 100vw;
    position: absolute;
    left: 0;
    z-index: -1;
    background: $prettyNavBg;
  }

  .pretty-tabs-nav-link.active {
    clip-path: inset(0 -50vw);

    .tabs-text {
      font-size: 1rem;
      font-weight: bold !important;
      color: theme('colors.medium-blue');
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      $infix: breakpoint-infix($breakpoint, $breakpoints);

      &.pretty-tabs-layout#{$infix} {
        &-row {
          .pretty-tabs-nav {
            flex-direction: row;
            height: 70px;
          }

          .pretty-tabs-active-triangle-pointer {
            width: 60px;
            height: 50px;
            bottom: 0;
            color: white;
            display: block;
          }

          ul {
            a {
              padding-top: .5rem !important;
              padding-bottom: .5rem !important;
            }
          }

          .pretty-tabs-nav-link.active {
            &:before {
              display: none;
            }
          }
        }

        &-column {
          .pretty-tabs-nav {
            flex-direction: column;
            height: unset;
          }

          .pretty-tabs-active-triangle-pointer {
            display: none;
          }

          ul {
            a {
              padding-top: .5rem;
              padding-bottom: .5rem;
            }

            a:first-of-type {
              padding-top: 12px;
            }

            a:last-of-type {
              padding-bottom: 15px;
            }
          }

          .pretty-tabs-nav-link.active {
            clip-path: inset(0 -50vw);

            &:before {
              background: rgba(255,255,255,.45);
              content: '';
              left: 0;
              width: 100vw;
              height: 400px;
              position: absolute;
              z-index: -1;
            }
          }
        }
      }
    }
  }

  &__destinations-tabs {
    clip-path: inset(0 -50vw 0 -50vw);
    letter-spacing: 0.5px;
    max-width: 100%;

    &:before {
      content: '';
      height: 100vh;
      width: 100vw;
      position: absolute;
      left: 0;
      z-index: -1;
      background: theme('colors.light-turquoise');
    }

    .pretty-tabs-nav-link.active {
      clip-path: inset(0 -50vw);

      .tabs-text {
        font-size: 1rem;
        font-weight: bold !important;
        color: theme('colors.medium-blue');
      }
    }
  }
}
</style>
